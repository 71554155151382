import { useCallback, forwardRef } from 'react';
import { makeStyles } from 'hooks';
import theme from 'theme';
import { Fab } from '@material-ui/core';
import Button from 'components/button';
import router from 'services/router';
import api from 'services/api';
import auth from 'services/auth';
import config from 'config';
import cc from 'classnames';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.isBranded ? theme.palette.primary.main : 'rgb(85, 26, 139)',
    textDecoration: 'none'
  }
}));

export default forwardRef(function NavLink(props, ref) {
  let { name, params, query, hash, children, onClick, href, button, fab, view, fileLink, fileCurrentWindow, helpLink, target, rel, className, stopPropagation, orgHint, ...other } = props;

  const classes = useStyles(props);

  if (helpLink) {
    href = config.help_path + helpLink;
    if (!target) { target = '_blank'; }
  }

  // Any target _blank should use noopener/noreferrer rel
  if (target === '_blank') {
    rel = 'noopener noreferrer';
  }

  const isLink = !(view || fab || button);
  className = cc(className, isLink && classes.link);
  const needsToSwitchOrgs = !!orgHint && orgHint !== auth.user?.orgId;

  if (needsToSwitchOrgs) {
    hash = hash || {};
    hash.expectedOrgId = orgHint;
  }
  const actualHref = name ? router.buildUrl(name, params, query, hash) : (fileLink ? api.openFileUrl(fileLink) : href) || (fab ? undefined : '#');

  const View = view || (fab ? Fab : (button ? Button : 'a'));

  const handleClickLink = useCallback((e) => {
    let shouldPreventDefault = true;
    let stopP = false;
    if (!onClick || onClick(e) !== false) {
      const handleNav = (shouldPrevent) => {
        if (name) {
          const method = target === '_blank' ? 'navigateToNewWindow' : 'navigateTo';
          router[method](name, params, query, hash);
        } else if (fileLink) {
          api.openFile(fileLink, fileCurrentWindow);
        } else if (shouldPrevent) {
          shouldPreventDefault = false;
        } else {
          window.location.href = actualHref;
          stopP = true;
        }
      };

      // We may need to switch accounts before handling the navigation, based on org hint
      if (needsToSwitchOrgs) {
        auth.switchOrg(orgHint, true).then(() => handleNav(false));
        shouldPreventDefault = true;
        stopP = true;
      } else {
        handleNav(true);
      }
    }

    if (shouldPreventDefault) { e.preventDefault(); }
    if (stopP || stopPropagation) { e.stopPropagation(); }
  }, [ fileLink, hash, name, onClick, params, query, target, stopPropagation, fileCurrentWindow, orgHint, needsToSwitchOrgs, actualHref ]);

  if (helpLink && theme.brand.hideHelp) { return null; }

  return <View
    ref={ref}
    href={actualHref}
    target={target}
    rel={rel}
    onClick={handleClickLink}
    button={view ? button : undefined}
    className={className}
    tabIndex={0}
    { ...other }
  >
    {children}
  </View>;
});